import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import MediaQuery from "react-responsive";


if (typeof window !== `undefined`) {
  require("intersection-observer");
}

const HomepageVideoSection = styled.div`
  position: relative;
  z-index: ${props => props.zIndex || 1};
  min-height: 100vh;
  width: 100%;
  



  .absolute-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip: rect(0, auto, auto, 0);
    @media (max-device-width: 775px) and (max-device-height: 1000px) {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; 
    }



    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    & > .hp-text-wrapper {
      position: sticky;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      @media (max-device-width: 775px) and (max-device-height: 1000px) {
        top: 320px;
        transform: translateY(0);
      }
      

      z-index: ${props => (props.zIndex ? props.zIndex + 1 : 1)};


      & > .cta {
        position: absolute;
        left: 0;
        margin-left: 50%;
        border: 0;
        transform: translateX(-50%);
        border-radius: 30px;
        text-transform: uppercase;
        padding: 15px 40px;
        margin-top: 15px;
        font-family: NewlineTextBold;
        font-size: 12px;
        letter-spacing: 3px;
        outline: none;
        background: #fff;
        transition: 0.2s;

        &:hover {
          cursor: pointer;
          background-color: #003dcf;
          color: #fff;
        }

        @media (max-width: 767px) {
          min-width: 220px;
          margin-top: 50px;
          padding: 15px 30px;

        }
      }
    }

    & > .hp-video {
      position: fixed;
      top: 0;
      left: 0;
      @media (max-device-width: 775px) and (max-device-height: 1000px) {
        min-height: 820px;
      }
    }
  }

  &.in-view {
    .absolute-wrapper > .hp-text-wrapper {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      @media (max-device-width: 775px) and (max-device-height: 1000px) {
        top: 320px;
        transform: translateY(0);
      }
    }

    // & + & {
    //   top: 50%;
    //   bottom: auto;
    //   transform: translateY(-50%);
    // }
  }
`;

class HomepageVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: window.screen.availWidth < 768 });
  }

  getVideo() {
    const { mobileVideo, desktopVideo } = this.props;
    const { isMobile } = this.state;
    return isMobile && mobileVideo ? mobileVideo : desktopVideo;
  }

  render() {
    const { headline, inView, zIndex, elementRef, cta } = this.props;
    const videoSectionProps = {
      zIndex: zIndex,
      className: `${inView ? "in-view" : ""}`,
    };

    if (inView) videoSectionProps.ref = elementRef;

    return (
      <HomepageVideoSection {...videoSectionProps}>
        <div className={`absolute-wrapper `}>
          <video
            autoPlay={true}
            muted={true}
            loop={false}
            playsInline={true}
            className="hp-video"
          >
            <source src={this.getVideo()} type="video/mp4" />
          </video>
          <div className="hp-text-wrapper">
            <h4>{headline}</h4>
            {cta && (
              <MediaQuery key="desktop" query="(min-width: 768px)">
              <button className="cta" onClick={() => navigate("/reserve")}>
                {cta}
              </button>
              </MediaQuery>
              )}
              {cta && (
              <MediaQuery key="mobile" query="(max-width: 767px)">
                <button className="cta" onClick={() => navigate("/reserve-mobile")}>
                {cta}
              </button>
              </MediaQuery>
            )}
          </div>
        </div>
      </HomepageVideoSection>
    );
  }
}

export default HomepageVideo;
