import React from "react";

import styled from "styled-components";

import Layout from "../components/layout";
import hp1VideoD from "../videos/home/home-1-d.mp4";
import hp1VideoM from "../videos/home/home-1-m.mp4";
import hp2VideoD from "../videos/home/home-2-d.mp4";
import hp2VideoM from "../videos/home/home-2-m.mp4";
import hp3VideoD from "../videos/home/home-3-d.mp4";
import hp3VideoM from "../videos/home/home-3-m.mp4";
import hp4VideoD from "../videos/home/home-4-d.mp4";
import hp4VideoM from "../videos/home/home-4-m.mp4";

import HomepageVideo from "../components/homepage-video";

import "./index.scss";

const VerticalCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const WhatIfWeCould = styled(VerticalCenter)`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  min-height: calc(100vh - 80px);
  pointer-events: none;

  @media only screen and (max-device-width: 768px) {
    min-height: 550px;
  }
`;

const homepageVideoArray = [
  {
    id: 1,
    title: "More than just a cup",
    desktopVideo: hp1VideoD,
    mobileVideo: hp1VideoM,
  },
  {
    id: 2,
    title: "A radical approach",
    desktopVideo: hp2VideoD,
    mobileVideo: hp2VideoM,
  },
  {
    id: 3,
    title: "Simple, smart, sustainable",
    desktopVideo: hp3VideoD,
    mobileVideo: hp3VideoM,
  },
  {
    id: 4,
    title: "A new way for beverages",
    desktopVideo: hp4VideoD,
    mobileVideo: hp4VideoM,
    cta: "Reserve Your Uno",
  },
];

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: homepageVideoArray,
      inViewId: 1,
    };

    this.lastScrollTop = 0;
    this.scrollListener = debounce(() => {
      if (!this.currentVideoInViewElement) return;
      if (isAnyPartOfElementInViewport(this.currentVideoInViewElement)) return;

      let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop) {
        // downscroll code
        this.setInView(this.state.inViewId + 1);
      } else {
        // upscroll code
        this.setInView(this.state.inViewId - 1);
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, 10);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollListener);
  }

  setInView(id) {
    this.setState({ inViewId: id });
  }

  render() {
    const { inViewId, videos } = this.state;
    return (
      <Layout currentPage="homepage">
        <WhatIfWeCould>
          <h3>this is</h3>
        </WhatIfWeCould>

        {videos.map(({ id, title, desktopVideo, mobileVideo, cta }) => {
          const videoProps = {
            key: id,
            headline: title,
            desktopVideo: desktopVideo,
            mobileVideo: mobileVideo,
            inView: inViewId === id,
            zIndex: 10 - id,
            setInView: this.setInView.bind(this, id),
            cta,
          };

          if (inViewId === id)
            videoProps.elementRef = el => {
              this.currentVideoInViewElement = el;
            };

          return <HomepageVideo {...videoProps} />;
        })}
      </Layout>
    );
  }
}

function isAnyPartOfElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const vertInView = rect.top < windowHeight && rect.top + rect.height > 0;
  return vertInView;
}

function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default IndexPage;
